.nav-wrappper {
    background: rgba(0, 0, 0, 0.1); /* Semi-transparent black as in the first image */
    padding: 0.1rem 0; /* Reduced padding */
    position: sticky;
    top: 0;
    z-index: 100;
    backdrop-filter: blur(50px);
}

.nav-content {
    display: flex;
    max-width: 1300px;
    align-items: center;
    justify-content: space-between;
    padding: 0.2rem 0; /* Reduced padding to make the navbar narrower */
    margin: 0 auto;
}

.nav-logo img {
    width: 150px;
    padding: 4px; /* Reduced padding to keep the logo size the same */
}

.nav-content ul {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
}

.nav-content li {
    margin: 0 1.5rem;
}

.menu-item {
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 500;
    color: var(--text-color); /* Ensure this color shows on the navbar background */
    position: relative;
    cursor: pointer;
}

.menu-item::before {
    content: " ";
    width: 2rem;
    height: 0.2rem;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%); /* Purple gradient for the underline */
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.6rem;
    opacity: 0;
    transform: translateX(-1.5rem);
    transition: all 0.3s ease;
}

.menu-item:hover::before {
    width: 100%;
    transform: translateX(0);
    opacity: 1;
}

.contact-btn {
    font-size: 0.9rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
    padding: 0.6rem 2rem;
    border: none;
    border-radius: 0.2rem;
    outline: 1.5px solid transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 20px;
}

.contact-btn:hover {
    color: #fff;
    background: var(--primary-color);
    outline: 1.5px solid var(--primary-color);
}

.menu-btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: none;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: var(--text-color);
    background: linear-gradient(90deg, var(--accent-color) 0%, var(--hover-color) 100%);
    cursor: pointer;
    transition: all 0.4s ease;
}

.menu-btn:hover {
    color: #fff;
    background: var(--text-color);
    border: 1px solid var(--primary-color);
}

/* RESPONSIVE RELATED STYLING */
@media (max-width: 425px) {
    .logo {
        width: 2rem;
        height: auto;
    }
    .nav-logo img {
        width: 75px;
        padding: 4px; /* Reduced padding to keep the logo size the same */
    }
}

@media (max-width: 769px) {
    .mobile-menu {
        width: 80%;
    }
    .menu-btn {
        display: flex; /* Show the hamburger menu in mobile view */
    }
    .nav-content ul {
        display: none; /* Hide the regular menu items in mobile view */
    }
    .nav-logo img {
        width: 100px;
        padding: 4px; /* Reduced padding to keep the logo size the same */
    }
}

@media (max-width: 1325px) {
    .nav-wrappper {
        padding: 0 2rem;
    }
}

@media (min-width: 769px) {
    .mobile-menu {
        display: none !important;
    }
}
