
.container {
    max-width: 1300px !important;
    margin: 0 auto;
    position: relative;
    background-color: var(--background-color);
    color: var(--text-color);
}

/* RESPONSIVE RELATED STYLING */
@media (max-width: 1300px) {
    .container {
        padding: 0 1.5rem;
    }
}
