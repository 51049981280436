.answered-container {
  max-width: 800px;
  margin: auto;
  padding: 1rem;
}

.answered-container h2 {
  color: var(--color-primary);
  text-align: center;
}

.question-card {
  background-color: white;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-text {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.answer-text {
  color: black;
  margin-bottom: 1rem;
}

.delete-button {
  padding: 0.5rem 1rem;
  background-color: var(--color-primary);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: var(--color-error);
}

.back-button {
  display: block;
  width: max-content;
  margin: 1rem auto;
  background-color: var(--color-accent);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.back-button:hover {
  background-color: var(--color-accent); /* Use the same color for the hover effect */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .question-card {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .answered-container {
    margin: 10px;
    padding: 0.5rem;
  }
}
