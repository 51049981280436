.hero-container {
  display: flex;
  align-items: flex-start; /* Align items at the top */
  justify-content: space-between;
  position: relative;
  color: var(--text-color);
  margin-top: 8px;
  padding: 24px;
}

.hero-container::after,
.hero-container::before {
  content: " ";
  width: 28.125rem;
  height: 28.125rem;
  border-radius: 50%;
  background: var(--primary-color);
  position: absolute;
  z-index: -1;
  filter: blur(225px);
}

.hero-container::after {
  top: -3rem;
  right: -3rem;
}

.hero-container::before {
  bottom: -3rem;
  left: -3rem;
}

.hero-content {
  flex: 1;
  padding-right: 2rem;
}

.hero-content h2 {
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1rem;
  margin-top: 0; /* Ensure no extra top margin */
  color: var(--text-color);
}

.hero-content p {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--text-color);
}

.hero-btn {
  font-size: 0.9rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: var(--accent-color);
  padding: 0.6rem;
  border: none;
  border-radius: 0.2rem;
  outline: 1.5px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.hero-btn:hover {
  color: var(--text-color);
  background: var(--hover-color);
  outline: 1.5px solid var(--text-color);
}

.hero-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; /* Align image at the top */
}

.hero-img > img {
  margin-top: 0; /* Remove top margin */
  width: 100%;
  max-width: 25rem;
  transition: all 0.3s ease;
  border-radius: 2%;
}

.icons {
  width: 5rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 0.4rem;
}

.icons img {
  margin-top: 2rem;
  width: 100%;
  height: auto;
  transition: all 0.3s ease;
  border: 1px solid var(--text-color);
  padding: 8px;
  border-radius: 0.65rem;
}

.icons img:hover {
  transform: scale(1.1);
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .hero-container::after,
  .hero-container::before {
      width: 20rem;
      height: 20rem;
      filter: blur(150px);
  }
}

@media (max-width: 768px) {
  .hero-container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .hero-content {
      padding-right: 0;
      text-align: center;
  }

  .hero-content h2 {
      font-size: 2.5rem;
  }

  .hero-content p {
      font-size: 0.9rem;
  }

  .hero-img > img {
      margin-top: 1rem;
      max-width: 20rem;
  }

  .icons {
      width: 4rem;
      height: 4rem;
  }

  .icons img {
      width: 100%;
      height: auto;
      margin-top: 1rem;
      padding: 0.5rem;
  }
}
