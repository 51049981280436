@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
    font-family: 'Montserrat', sans-serif;
}

:root {
    --primary-color: #0C4D89; /* deep blue */
    --text-color: #fff; /* Dark gray */
    --background-color: #00857F; /* Updated background color to a teal */
    --accent-color: #9A1E59; /* Updated accent color to a deep maroon */
    --hover-color: #f4cae0; /* Light pink, remains unchanged */
    --error-color: #B71C1C; /* Darker red for errors, remains unchanged */
    --footer-background-color: #130f2a; /* Dark background, remains unchanged */
    --success-color: #388E3C; /* Green for success messages, remains unchanged */
    --footer-link-color: #ffffff; /* White, remains unchanged */
    --footer-link-hover-color: #f4cae0; /* Light Pink, remains unchanged */
    --footer-social-icon-color: #ffffff; /* White, remains unchanged */
    --footer-social-icon-hover-color: #f4cae0; /* Light Pink, remains unchanged */
    --footer-border-color: #ffffff; /* White, remains unchanged */
}


html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  
* {
    font-family: 'Montserrat', sans-serif;
    margin: 0;
    box-sizing: border-box;
}

body {
    color: var(--text-color);
    background-color: var(--background-color);
}

body[dir="rtl"] {
    direction: rtl;
    text-align: right;
  }
  
  body[dir="ltr"] {
    direction: ltr;
    text-align: left;
  }
