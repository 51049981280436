.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    background-color: var(--background-color); /* Using your teal background color */
    color: var(--text-color); /* Using your dark gray text color */
}

.not-found-container h1 {
    font-size: 2.5rem;
    margin-bottom: 0.5em;
}

.not-found-container p {
    font-size: 1.2rem;
    margin-bottom: 1em;
}

.home-link {
    font-size: 1.1rem;
    padding: 10px 20px;
    color: var(--footer-link-color); /* Using your white link color */
    background-color: var(--primary-color); /* Using your deep blue primary color */
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.home-link:hover {
    background-color: var(--hover-color); /* Using your light pink hover color */
}
