.mobile-menu {
    width: 100vw; /* Corrected to use vw instead of vh for width */
    height: 100vh;
    display: block;
    background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay for backdrop */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
    transition: visibility 0s linear 0.3s, opacity 0.3s ease, transform 0.3s ease;
}

.mobile-container {
    display: flex;
    justify-content: space-between;
    max-width: 70%;
    align-items: center;
}

.mobile-menu-container {
    position: relative;
    width: 90vw; /* Corrected to use vw instead of vh for width */
    height: 100vh;
    background-color: var(--hover-color); /* Updated to use the accent color from the logo */
    padding: 2rem;
    color: var(--text-color); /* Ensuring there is good contrast between text and the accent background */
}

.mobile-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
    transition-delay: 0s;
}

.mobile-logo {
    width: 100px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 3rem;
}

.mobile-menu ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    list-style: none;
    margin-left: -2rem;
    margin-bottom: 2rem;
}

.mobile-menu ul a {
    color: var(--text-color); /* Ensuring text color contrasts well with the accent background */
    font-weight: bold;
}

.mobile-menu .menu-item:hover,
.contact-btn:hover {
    color: var(--primary-color); /* Highlight color on hover, assuming good contrast with accent background */
}

.contact-btn {
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; /* Text color */
    background: var(--primary-color); /* Background color */
    padding: 0.6rem 1.5rem;
    border: none;
    border-radius: 0.2rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.close-menu-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: transparent;
    border: none;
    color: #000; /* Ensuring the close button is visible against the accent background */
    font-size: 1.5rem;
    cursor: pointer;
    margin-top: 2rem;
}

/* Adjustments for RTL (right-to-left) languages */
html[dir="rtl"] .mobile-menu {
    transform: translateX(100%);
}

html[dir="rtl"] .mobile-menu.active {
    transform: translateX(0);
}

html[dir="rtl"] .close-menu-btn {
    right: auto;
    left: 1rem;
}

/* RESPONSIVE RELATED STYLING */
@media (max-width: 769px) {
    .mobile-menu {
        display: block;
        width: 80%; /* Adjusted width for smaller screens */
    }
}
