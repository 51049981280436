.skills-container {
    margin: 4rem 0;
    position: relative;
}

.skills-container::after,
.skills-container::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 28.125rem;
    z-index: -1;
    filter: blur(10px); /* Apply blur effect */
}

.skills-container::after {
    top: 0;
    left: 0;
    background: var(--background-color); /* Use solid background color */
}

.skills-container::before {
    bottom: 0;
    right: 0;
    background: var(--accent-color); /* Use solid background color */
}


.skills-container h5 {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--text-color);
    margin-bottom: 2rem;
}

.skills-content {
    display: flex;
    align-items: flex-start;
    gap: 3rem;
}

.skills {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
}

.skills-info {
    flex: 1;
}
