.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: var(--background-color); /* Purple background */
}

.login-form {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--accent-color); /* Pink background */
  max-width: 400px;
  width: 100%;
}

.input-group {
  margin-bottom: 1rem;
}

.login-input {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  border-radius: 4px;
  border: 1px solid var(--footer-border-color); /* White border */
  background-color: var(--accent-color); /* Pink background */
  font-size: 1rem;
}

.login-input::placeholder {
  color: var(--text-color); /* Dark gray text color */
}

.action-group {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  color: var(--footer-link-color); /* White text color */
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  background-color: var(--primary-color); /* Blue background */
}

.login-button:hover {
  background-color: var(--hover-color); /* Light pink background on hover */
}

.login-error {
  color: var(--error-color); /* Darker red for error messages */
  margin-top: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .login-form {
    padding: 1rem;
  }
}

@media (max-width: 480px) {
  .login-container {
    padding: 1rem;
  }

  .login-form {
    width: calc(100% - 2rem);
  }
}
