.experience-container {
    margin: 4rem 0;
    position: relative;
}

.experience-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: center;
    color: var(--text-color);
}

.experience-cards-container {
    position: relative;
    width: 100%;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    cursor: pointer;
    background: var(--background-color);
    border-radius: 0.65rem;
    border: 1px solid var(--accent-color);
    width: 2.2rem;
    height: 2.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
}

.arrow-left {
    left: 20px;
}

.arrow-right {
    right: 20px;
}

.arrow svg {
    width: 20px;
    height: 20px;
}
