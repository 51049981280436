.skills-card {
    border-radius: 0.65rem;
    border: 1.5px solid var(--primary-color); /* Purple border */
    padding: 2.5rem;
    text-align: center;
    background: rgba(22, 17, 47, 0.398); /* Adjust the alpha for more or less transparency */
    backdrop-filter: blur(1rem);
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
}

.skills-card:hover,
.skills-card.active {
    background: var(--accent-color); /* Beige background for hover/active state */
}

.skills-card span {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--text-color); /* Text color for readability */
}

.skill-icon {
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-color); /* Purple background for the icon */
    border-radius: 0.65rem;
    border: none; /* Remove border if the icon background is already purple */
    position: absolute;
    top: -1rem;
    left: -1rem;
}

.skill-icon svg {
    width: 35px;
    height: 35px;
    fill: var(--background-color); /* Use beige for SVG icon color */
}

/* RESPONSIVE RELATED STYLING */
@media (max-width: 1025px) {
    .skills-card {
        padding: 2rem;
    }
    .skills-card span {
        font-size: 1rem;
    }
    .skill-icon {
        width: 3rem;
        height: 3rem;
    }
    .skill-icon svg {
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 768px) {
    .skills-card span {
        font-size: 0.9rem;
    }
}
