.contact-page {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  color: var(--text-color); /* Dark gray text color */
}

.contact-form-section,
.contact-info-section {
  flex: 1;
  min-width: 300px; /* Minimum width for smaller screens */
}

.contact-information,
.location-map {
    background: var(--background-color); /* Purple background from logo */
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    color: var(--text-color); /* Dark gray text color */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Subtle shadow effect */
}

.contact-information h2,
.location-map h2 {
  color: var(--primary-color); /* Blue color from logo for headings */
}

.social-media-links a {
  color: var(--text-color); /* Dark gray text color for links */
  text-decoration: none;
  margin-right: 10px;
}

.social-media-links a:hover {
  text-decoration: underline;
  color: var(--hover-color); /* Light pink for hover states */
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icons a {
  font-size: 24px; /* Size of the icons */
  color: var(--text-color); /* Dark gray text color for icons */
  margin: 0 10px; /* Spacing between icons */
}

.social-icons a:hover,
.social-icons a:focus {
  color: var(--primary-color); /* Blue color for icon hover */
}

.location-map iframe {
  width: 100%;
  max-width: 100%;
  height: 250px; /* Adjust height as needed */
  border-radius: 10px;
  border: none; /* Remove default border */
}

/* Responsive design */
@media (max-width: 768px) {
  .contact-page {
      flex-direction: column;
  }
  .location-map iframe {
      height: 200px;
  }
}
