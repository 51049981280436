.forum-page {
  color: #ffffff; /* White text for contrast */
  font-family: 'Arial', sans-serif; /* Standard font */
  padding: 20px;
}

.forum-page h1 {
  text-align: center;
  color: var(--primary-color); /* Blue color for the heading */
  margin-bottom: 1em;
}

.ask-question-form {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Maximum width of the form */
  margin: 0 auto 2em;
  background-color: var(--background-color); /* Purple background for the form area */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  
}

.ask-question-form input,
.ask-question-form button {
  padding: 10px;
  margin-bottom: 1em;
  border-radius: 5px;
  border: none;
}

.ask-question-form input {
  background: var(--background-color); /* Input background color */
  color: var(--text-color); /* Input text color */
}

.ask-question-form input::placeholder {
  color: #ffffff; /* Explicitly setting the placeholder text to white */
}

.ask-question-form button {
  background: linear-gradient(90deg, #a993fe 0%, var(--primary-color) 100%); /* Gradient for buttons */
  color: #fff; /* White text for buttons */
  cursor: pointer;
  transition: background-color 0.3s;
}

.ask-question-form button:hover {
  background: #7e61e7; /* Hover effect for the button */
}

.questions-list {
  max-width: 800px; /* Maximum width of the questions list */
  margin: 0 auto;
  background-color: var(--background-color); /* Purple background for the questions list */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow effect for each question item */
}

.question-item {
  background: var(--footer-background-color); /* Light pink background for contrast */
  border-left: 4px solid var(--primary-color); /* Blue highlight color for the left border */
  padding: 15px;
  margin-bottom: 1em;
  border-radius: 5px;
}

.question-item h3 {
  font-weight: normal;
  font-size: 1.1em;
  margin-bottom: 0.5em;
}

.answer,
.no-answer {
  color: var(--text-color); /* Dark gray text color for answers */
}

.error {
  color: var(--error-color); /* Darker red for error messages */
  margin-top: 10px;
}

.no-questions {
  text-align: center;
  margin-top: 20px;
}

