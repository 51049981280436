.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: var(--text-color); /* Dark grey text from logo color scheme */
}

.about-hero {
  text-align: center;
  background-color: var(--background-color); /* Purple background from logo */
  padding: 4rem 2rem;
  margin-bottom: 2rem;
  border-radius: 8px;
}

.about-hero h1 {
  color: var(--primary-color); /* Blue color from logo for main headings */
  margin-bottom: 1rem;
}

.about-hero p {
  color: var(--text-color); /* Dark grey text for paragraphs */
  font-size: 1.1rem;
}

.about-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.about-bio,
.about-qualifications {
  flex-basis: 48%; /* Splits the container into two columns */
  background-color: var(--background-color); /* Purple background from logo */
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
}

.about-bio h2,
.about-qualifications h2 {
  color: var(--primary-color); /* Blue color from logo for subheadings */
  margin-bottom: 1rem;
}

.about-qualifications ul {
  list-style: inside square; /* Or any other style you prefer */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .about-details {
    flex-direction: column;
  }
  .about-bio,
  .about-qualifications {
    flex-basis: 100%;
    margin-bottom: 1rem;
  }
}
