.footer-container {
    background-color: #130f2a; /* Dark background */
    color: var(--footer-text-color);
    padding: 20px;
    text-align: center;
    font-size: 16px;
    border-top: 1px solid var(--footer-border-color);
}

.footer-container a {
    color: var(--footer-link-color);
    text-decoration: none;
    margin: 0 10px;
}

.footer-container a:hover {
    text-decoration: none;
    color: var(--footer-link-hover-color);
    transform: scale(1.1);
}

.social-links a {
    font-size: 24px;
    color: var(--footer-social-icon-color);
}

.social-links a:hover {
    color: var(--footer-social-icon-hover-color);
}

.footer-links a {
    font-weight: 500;
}

.developer-link:hover {
    transform: scale(1.2);
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
    }
}
