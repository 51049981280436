.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px; /* Reduced space between buttons */
}

.language-switcher button {
  background-color: var(--primary-color); /* Use primary color */
  color: var(--text-color); /* Use text color */
  border: none;
  padding: 5px 10px; /* Reduced padding */
  cursor: pointer;
  border-radius: 3px; /* Slightly reduced border radius */
  font-size: 14px; /* Reduced font size */
  transition: background-color 0.3s ease;
}

.language-switcher button:hover {
  background-color: var(--background-color); /* Use background color for hover */
}

.language-switcher button:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--hover-color); /* Slightly reduced focus outline */
}

/* Media query for extra small screens */
@media (max-width: 576px) {
  .language-switcher button {
    padding: 4px 8px; /* Further reduced padding */
    font-size: 12px; /* Further reduced font size */
  }
}
