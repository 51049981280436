.contact-container {
    background: var(--background-color);
    padding: 4rem;
    text-align: center;
    border-radius: 0.65rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    margin: 2rem 0;
    color: var(--text-color);
}

.contact-container h5 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 2rem;
    color: var(--primary-color);
}

.contact-content {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2rem;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 1rem;
    border-radius: 0.65rem;
    border: 1px solid var(--accent-color);
    background: var(--background-color);
    color: var(--text-color);
    margin-bottom: 1rem;
    resize: none;
}

.contact-form input::placeholder{
    color: var(--text-color);
}
.contact-form textarea::placeholder{
    color: var(--text-color);
}

.contact-form textarea {
    height: 150px;
}

.contact-form button {
    padding: 1rem 2rem;
    border-radius: 0.65rem;
    border: none;
    color: var(--background-color);
    background-color: var(--accent-color);
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.contact-form button:hover {
    background-color: var(--hover-color);
}

.contact-info {
    flex: 1;
    color: var(--text-color);
}

.error {
    color: var(--error-color);
    margin-top: 1rem;
}

.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loader {
    width: 500px;
}
