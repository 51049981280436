.unanswered-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 1rem;
  color: var(--color-accent);
}

.unanswered-container h2 {
  color: var(--color-primary);
  text-align: center;
}

.question-card {
  background-color: white;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.question-text {
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: black;
}

.answer-form {
  display: flex;
  flex-wrap: wrap; /* Allow inputs and buttons to wrap on smaller screens */
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.answer-input {
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.answer-button, .delete-button, .back-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  color: white;
  background-color: var(--primary-color);
  cursor: pointer;
  transition: background-color 0.2s;
}

.answer-button:hover, .delete-button:hover {
  background-color: var(--color-error);
}

.back-button {
  display: block;
  width: max-content;
  margin: 1rem auto;
  background-color: var(--color-accent); /* Dark color for the back button */
}

/* Responsive design adjustments */
@media (max-width: 768px) {
  .question-card {
    padding: 0.5rem;
  }

  .answer-input {
    flex-grow: 0;
    flex-basis: calc(100% - 2rem); /* Input field takes full width minus button width */
  }

  .answer-button, .delete-button {
    flex-grow: 0;
    flex-basis: calc(50% - 0.5rem); /* Button takes half width minus margin */
  }

  .back-button {
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 480px) {
  .unanswered-container {
    margin: 10px;
    padding: 0.5rem;
  }

  .question-card {
    margin-bottom: 0.5rem;
  }
}
