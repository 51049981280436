.work-experience-card {
    background: var(--accent-color);
    border-radius: 0.65rem;
    padding: 1.5rem;
    margin: 1rem;
    display: flex;
    align-items: center;
    color: var(--text-color);
    transition: all 0.3s ease;
}

.experience-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
}

.experience-icon svg {
    width: 35px;
    height: 35px;
    fill: var(--footer-background-color);
}

.experience-content {
    display: flex;
    flex-direction: column;
}

.experience-title {
    background: var(--primary-color);
    border-radius: 0.3rem;
    padding: 0.4rem 0.6rem;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--footer-background-color);
    display: inline-block;
}

.experience-content p {
    font-size: 1.1rem;
    color: var(--text-color);
    font-weight: 500;
    margin-top: 0.5rem;
}
