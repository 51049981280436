.faq-page {
  color: #ffffff; /* White text for contrast */
  font-family: 'Arial', sans-serif; /* Standard font */
  padding: 20px;
  text-align: center; /* Center all content within the .faq-page */
}

.faq-page h2 {
  color: var(--text-color); /* Text color for the heading */
  margin-bottom: 1em;
  margin-top: 1em; /* Add margin on top for spacing */
  text-align: center; /* Ensure the heading is centered */
}

.faq-list {
  max-width: 800px; /* Maximum width of the FAQ list */
  margin: 0 auto;
  background-color: var(--background-color); /* Teal background for the FAQ list */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3); /* Shadow effect for each FAQ item */
}

.faq-item {
  background: var(--footer-background-color); /* Dark background for contrast */
  border-left: 4px solid var(--primary-color); /* Highlight color for the left border */
  padding: 15px;
  margin-bottom: 1em;
  border-radius: 5px;
  cursor: pointer;
}

.faq-question-container {
  display: flex; /* Use flexbox to align question and icon */
  justify-content: space-between; /* Space between question and icon */
  align-items: center; /* Center align the items vertically */
}

.faq-question {
  font-weight: normal;
  font-size: 1.1em;
  margin-bottom: 0.5em;
  cursor: pointer; /* Make the question text clickable */
}

.faq-icon {
  font-size: 1.5em; /* Size of the icon */
  color: var(--text-color); /* Icon color */
}

.faq-answer {
  color: var(--text-color); /* Text color for answers */
  margin-top: 0.5em;
}

.faq-separator {
  width: 100%;
  height: 1px;
  background-color: var(--primary-color); /* Color for the separator line */
  margin: 10px 0; /* Spacing around the separator */
}
