:root {
  --primary-color: #64a6bd; /* Blue */
  --text-color: #424242; /* Dark gray */
  --background-color: #ada7c9; /* Purple */
  --accent-color: #d7b9d5; /* Pink */
  --hover-color: #f4cae0; /* Light pink */
}

.dashboard-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 1rem;
}

.dashboard-header {
  text-align: center;
  margin-bottom: 2rem;
}

.dashboard-header h2 {
  color: var(--accent-color); /* Pink color */
}

.dashboard-nav {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 2rem;
}

.dashboard-button, .delete-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  color: var(--text-color); /* Dark gray text color */
  background-color: var(--primary-color); /* Blue background */
  cursor: pointer;
  transition: background-color 0.3s;
}

.dashboard-button:hover, .delete-button:hover {
  background-color: var(--hover-color); /* Light pink background on hover */
}

.questions-container {
  margin-top: 1rem;
}

.question {
  background-color: #fff; /* White background for question card */
  color: var(--accent-color); /* Pink text color for the question */
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  font-weight: bold;
}

.answer-text {
  color: var(--accent-color); /* Pink text color for the answer */
  margin-top: 0.5rem;
}

@media (max-width: 768px) {
  .dashboard-header, .dashboard-nav {
    flex-direction: column;
    align-items: center;
  }

  .dashboard-button, .delete-button {
    width: 100%;
    margin-bottom: 0.5rem;
  }

  .question {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .dashboard-container {
    padding: 0.5rem;
  }

  .dashboard-button, .delete-button {
    width: 100%;
  }
}
